import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const customInnerSectionStyles = css`
  ${atMinWidth.md`
    max-width: 100%;
    padding: 72px 0px;
  `}

  ${atMinWidth.md`
    padding: 96px 0px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${atMinWidth.sm`
   gap: 48px;
   `}

  ${atMinWidth.lg`
   gap: 64px;
   `}

  .cta-container {
    display: flex;
    justify-content: center;
  }
`;

export const Slider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .slick-list {
    min-height: 340px;
    display: none;

    ${atMinWidth.sm`
      display: block;
    `}
  }

  .slider-arrows {
    display: none;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin: 0 auto;
    max-width: 50px;

    div {
      cursor: pointer;
    }

    ${atMinWidth.sm`
      display: flex;
    `}

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .slick-track {
    margin-bottom: 24px;
  }

  .mobile-card-deck {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .benefits-card {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ${atMinWidth.sm`
      display: none;
    `}
  }

  .benefits-card {
    overflow: visible;
  }
`;
