import React from 'react';
import Card from 'react-bootstrap/Card';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import { colors } from '../../styles/atoms/colors';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';
import Hex from './assets/Hex';
import { CardStyles } from './styles/BasicCard.styled';

const BasicCard = ({ className, card }) => {
  const {
    background,
    heading,
    subhead,
    cardImage,
    link,
    inlineHeading,
    headingColor,
    blockHeading,
  } = card;

  return (
    <CardStyles
      inlineHeading={inlineHeading}
      headingColor={headingColor?.hex}
      layout={link ? 6 : 3}
      hasLink={link?.url}
      hasLabel={link?.label}
      blockHeading={blockHeading}
      background={background}
      className={className}
    >
      {background === 'hex-grey' && (
        <Hex className="cardBgHex" color={colors.gray[50]} />
      )}
      <Link className="card-link" to={link?.url}>
        <Card>
          <Card.Body>
            <Card.Title>
              <figure className="card-image">
                {cardImage?.image?.gatsbyImageData ? (
                  <Img
                    image={cardImage?.image?.gatsbyImageData}
                    alt={cardImage?.internalName}
                    title={cardImage?.image?.title}
                  />
                ) : (
                  <Img
                    src={cardImage?.image?.url}
                    alt={cardImage?.internalName}
                    title={cardImage?.image?.title}
                  />
                )}
              </figure>
              <p className="card-heading">{heading}</p>
            </Card.Title>
            <div
              dangerouslySetInnerHTML={{ __html: subhead }}
              className="subhead"
            />
            {link?.label && (
              <CallToAction
                variant="linkColor"
                icon="right"
                link={link?.url}
                value={link.label}
                size="lg"
              />
            )}
          </Card.Body>
        </Card>
      </Link>
    </CardStyles>
  );
};

export default BasicCard;
