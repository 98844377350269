import React, { useRef } from 'react';
import ReactSlider from 'react-slick';

import Section from '../../../molecules/Section/Section';

import CardDeckHeader from '../components/CardDeckHeader';
import BasicCard from '../../Cards/BasicCard';

import { NextArrow, PrevArrow } from '../../base/Arrows';

import CallToAction from '../../../molecules/CallToAction/CallToAction';

import {
  customInnerSectionStyles,
  Wrapper,
  Slider,
} from '../styles/SliderCardDeck.styled';

const CardDeckSlider = ({ component }) => {
  const { id, cards, cta } = component;
  const sliderRef = useRef();

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    draggable: true,
    useTransform: false,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    initialSlide: 0,
    speed: 500,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Section id={id} innerStyle={customInnerSectionStyles}>
      <Wrapper>
        <CardDeckHeader {...component} />
        <Slider>
          <ReactSlider {...settings} ref={sliderRef}>
            {cards &&
              cards.map(card => (
                <BasicCard className="benefits-card" card={card} />
              ))}
          </ReactSlider>
          <div className="mobile-card-deck">
            {cards &&
              cards.map(card => (
                <BasicCard className="benefits-card" card={card} />
              ))}
          </div>
          <div className="slider-arrows">
            <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
            <NextArrow onClick={() => sliderRef.current.slickNext()} />
          </div>
        </Slider>
        {
            cta
            ?
            (
              <div className="cta-container">
                <CallToAction
                  variant={cta.variant}
                  size={cta.size}
                  value={cta.label}
                  link={cta.url}
                  icon={cta.icon}
                  reverse={cta.reverse}
                />
              </div>
            )
            :
            ''
        }
      </Wrapper>
    </Section>
  );
};

export default CardDeckSlider;
