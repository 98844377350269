import React from 'react';

const Hex = ({ color = '#F9FBFE', className }) => (
  <svg
    width="541"
    height="378"
    viewBox="0 0 541 378"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M176.173 20.905C188.254 5.69266 207.533 -1.91131 226.746 0.957412L487.319 39.8635C506.532 42.7322 522.761 55.6378 529.893 73.7189L626.615 318.937C633.746 337.018 630.697 357.528 618.616 372.74L454.765 579.052C442.683 594.265 423.405 601.869 404.192 599L143.619 560.094C124.406 557.225 108.177 544.32 101.045 526.239L4.32292 281.02C-2.80881 262.939 0.240357 242.43 12.3218 227.217L176.173 20.905Z"
      fill={color}
    />
  </svg>
);

export default Hex;
