import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const CardStyles = styled.div`
  position: relative;
  height: 100%;
  border: ${props =>
    props.background === 'hex-grey' ? `solid 1px ${colors.gray[200]}` : ''};
  border-radius: 8px;
  overflow: hidden;

  .card-link {
    height: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .card {
    height: 100%;
    border: ${props =>
      props.inlineHeading || props.blockHeading
        ? 'none'
        : '0.5px solid #F0F2F5'};
    border-radius: ${props =>
      props.inlineHeading || props.blockHeading ? '6px' : '9px'};
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
    pointer-events: ${props => !props.hasLink && 'none'};
    ${props => props.background !== 'hex-grey' && shadow('md')};
    background-color: ${props =>
      props.background === 'hex-grey'
        ? colors.base.heroSurface
        : colors.base.white};

    &-image {
      overflow: hidden;
      margin-bottom: 0;
      position: relative;

      &::before {
        content: '';
        display: flex;
      }

      img {
        ${props => props.layout === 6 && 'position:absolute;'}
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-body {
      padding: 24px;
      min-height: unset;
      height: 50%;
      z-index: 101;

      ${atMinWidth.lg`
        height: auto;
      `}

      .link {
        color: var(--primary-color);
      }
    }

    &-title {
      ${font('text', 'md', '700')}
      margin-bottom: 16px;
      color: ${props =>
        props.headingColor ? props.headingColor : 'var(--dark-color)'};
      position: relative;
      display: ${props => props.inlineHeading && 'flex'};
      align-items: center;

      figure {
        display: ${props =>
          props.inlineHeading || props.blockHeading ? 'flex' : 'inline-block'};
        align-items: center;
        margin-right: ${props => props.inlineHeading && '12px'};
        margin-bottom: ${props => props.blockHeading && '16px'};
        vertical-align: top;
        width: 12px;
        width: ${props => props.inlineHeading && '24px'};
        height: ${props => props.inlineHeading && '28px'};
        width: ${props => props.blockHeading && '40px'};
        height: ${props => props.blockHeading && '40px'};

        img {
          object-fit: contain;
        }
      }

      .card-heading {
        ${font('text', 'md', '700')}
        display: inline-block;
        margin-right: 8px;
      }
    }

    &-arrow {
      transform: rotate(-90deg);
      filter: invert(19%) sepia(65%) saturate(7318%) hue-rotate(236deg)
        brightness(99%) contrast(105%);
    }

    .subhead {
      ${font('text', 'sm', '400')}
      color: ${colors.gray[900]};
      margin-bottom: ${props => props.inlineHeading && '16px'};
    }

    &:hover {
      text-decoration: none;
      box-shadow: 0px 8px 80px rgba(106, 114, 158, 0.2);
    }

    .link-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &.benefits-card {
    .card {
      max-height: 330px;
      height: 330px;
      margin: 0 20px;
      ${shadow('lg')};
      border-radius: 10px;

      ${atMinWidth.sm`
        max-width: 352px;
        max-height: 100%;
      `}

      .card-title {
        display: block;
        margin-bottom: 8px;

        .card-heading {
          ${font('text', 'lg', '700')}
          color: ${colors.gray[900]};
          margin-top: 24px;
          margin-bottom: 0;
          margin-right: 0;

          ${atMinWidth.sm`
            ${font('display', 'sm', '700')}
          `}          
        }

        figure {
          width: 56px !important;
          height: auto;
        }
      }

      .subhead {
        ${font('text', 'lg', 400)}
        color: ${colors.gray[700]};

        ${atMinWidth.sm`
          ${font('text', 'xl', 400)}
        `}
      }
    }
  }
`;
